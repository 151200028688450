<template>
  <div class="authorized-callback-view">
    <h4>Процесс получения токена авторизации...</h4>
    <div class="py-4 my-4">
      <b-spinner
          style="width: 3rem; height: 3rem;"
          variant="primary"
          label="Spinning"
      />
    </div>
    <span>Может занять несколько секунд!</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AuthorizedCallback',

  methods: {
    ...mapActions('auth', {
      useRefreshToken: 'useRefreshToken',
    }),
  },

  async mounted() {
    const refreshToken = this.$route.query['refresh-token'];
    if (!refreshToken) {
      await this.$router.push({ name: 'authorized-callback-error' });
      return;
    }

    localStorage.setItem('refreshToken', refreshToken);

    // Получение пары JWT и RefreshToken
    const { error } = await this.useRefreshToken();
    if (error !== undefined) {
      this.$router.push({
        name: 'authorized-callback-error',
        params: { error },
      });
    } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'forward')) {
      // Редирект на forward (прокидовали в ЕСИА)
      this.$router.push(this.$route.query.forward);
    } else {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>

<style lang="scss" scoped>
.authorized-callback-view {
  padding: 50px 10px;
  text-align: center;
}
</style>
